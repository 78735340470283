<template>
  <div class="wrapper">
    <div class="main-header text-center">
      <img src="@/assets/images/logo-trans.svg" alt="logo vinid" />
      <div class="note">{{ sloganData.note }}</div>
      <div class="slogan">{{ sloganData.label }}</div>
    </div>

    <div class="home-wrapper">
      <div class="content-wrapper">
        <div class="header-wrapper header-first clearfix">
          <div class="left left-content">
            <span class="number lbl-number">1</span>
          </div>
          <div class="left right-content">
            <h2 class="title">{{sectionOne.title}}</h2>
            <span class="sumary">{{ sectionOne.summary }}</span>
          </div>
        </div>
        <div class="content-card">
          <img
            class="img-content"
            src="@/assets/images/qcmerchant/ic-merchantqr-step-1.png"
            alt="merchantqr-step-1"
          />
        </div>
      </div>
      <!--1-->
      <div class="content-wrapper">
        <div class="header-wrapper header-seconds clearfix">
          <div class="right left-content">
            <span class="number lbl-number">2</span>
          </div>
          <div class="right right-content">
            <h2 class="title">{{ sectionTwo.title }}</h2>
            <span class="sumary">{{ sectionTwo.summary }}</span>
          </div>
        </div>
        <div class="content-card">
          <img
            class="img-content"
            src="@/assets/images/qcmerchant/ic-merchantqr-step-2.png"
            alt="merchantqr-step-1"
          />
        </div>
      </div>
      <!-- 2 -->
      <div class="content-wrapper">
        <div class="header-wrapper header-first clearfix">
          <div class="left left-content">
            <span class="number lbl-number">3</span>
          </div>
          <div class="left right-content">
            <h2 class="title">{{ sectionThree.title }}</h2>
            <span class="sumary">{{ sectionThree.summary }}</span>
          </div>
        </div>
        <div class="content-card">
          <img
            class="img-content"
            src="@/assets/images/qcmerchant/ic-merchantqr-step-3.png"
            alt="merchantqr step 3"
          />
        </div>
      </div>
      <!-- 3 -->
      <div class="button-download">
        <a class="btn-download cta" id="no-sticky" :href="url">{{ labels.download }}</a>
      </div>

      <div class="note">
        <p>{{ labels.copyRight }}</p>
        <p>{{ labels.qa }}</p>
      </div>
      <div class="button-download" id="sticky" v-if="scrollPosition">
        <a class="btn-download cta-sticky" :href="url">{{ labels.download }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MerchantQR",
  data() {
    return {
      scrollPosition: true,
      url: "https://vinid.page.link",
      sloganData: {
        note: "Dùng ứng dụng VinID để quét mã này, bạn có thể trải nghiệm",
        label: "Quét mã để thanh toán"
      },
      sectionOne: {
        title: "Quét mã của hàng",
        summary: "Thường đặt ở quầy thanh toán"
      },
      sectionTwo: {
        title: "Nhập số tiền",
        summary: "Nhập vào số tiền cần thanh toán"
      },
      sectionThree: {
        title: "Xong!",
        summary: "Nhanh chóng. An toàn."
      },
      labels: {
        download: "Tải ứng dụng VinID",
        copyRight: "Copyright 2019 by Vingroup. All rights reserved.",
        qa: "Câu hỏi thường gặp | Chính sách bảo mật"
      }
    };
  },
  mounted() {
    this.updateScroll();
    window.addEventListener("scroll", this.updateScroll);
    if (this.$route.params.pathMatch) {
      this.url =
        "https://vinid.page.link/?link=https://qr.id.vin/" +
        this.$route.params.pathMatch +
        "&apn=com.vingroup.vinid&isi=1215495998&ibi=com.vingroup.VinIDApp";
    }
  },

  destroy() {
    window.removeEventListener("scroll", this.updateScroll);
  },

  methods: {
    updateScroll() {
      let scrollY = window.scrollY;
      let height = window.innerHeight - scrollY - 75;
      if (!scrollY) {
        this.scrollPosition = true;
        return;
      }

      if (scrollY > 75) {
        this.scrollPosition = false;
        return;
      }

      if (height > 150) {
        this.scrollPosition = true;
      } else {
        this.scrollPosition = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  background-color: #fff9f4;

  .left {
    float: left;
  }

  .right {
    float: right;
  }

  .clearfix {
    &:after {
      content: "";
      clear: both;
      display: table;
    }
  }

  .main-header {
    height: 290px;
    box-shadow: 0 7px 13px 0 #dac3af;
    background-color: #1e9a45;
    border-bottom-left-radius: 100% 100px;
    border-bottom-right-radius: 100% 100px;

    img {
      width: 300px;
      height: 75px;
      margin: 0 auto;
      margin-top: 35px;
    }

    .note {
      padding: 30px 30px 0;
      font-size: 18px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
    }

    .slogan {
      font-size: 24px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      padding: 8px 16px 0;
    }
  }

  .home-wrapper {
    padding-top: 30px;

    .content-wrapper {
      h2 {
        font-size: 24px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.87);
      }
      .header-wrapper {
        position: relative;
        overflow: hidden;
        box-sizing: content-box;

        .left-content {
          .number {
            display: inline-block;
          }

          .lbl-number {
            font-size: 32px;
            font-weight: 900;
            color: #ffffff;
            line-height: normal;
          }
        }

        .right-content {
          .title {
            margin-bottom: 0;
            color: #412215;
            padding-top: 2px;
          }

          .sumary {
            display: inline-block;
            color: #412215;
          }
        }

        &.header-first {
          .number {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            background-color: #d8134b;
            padding: 7px 24px 7px 40px;
          }

          .right-content {
            padding-left: 8px;
            padding-bottom: 2px;
            text-align: left;

            .title {
              text-align: left;
            }
          }
        }

        &.header-seconds {
          .number {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            background-color: #1e9a45;
            padding: 7px 40px 7px 24px;
          }

          .right-content {
            padding-right: 8px;
            padding-bottom: 2px;

            .title {
              text-align: right;
            }
          }
        }
      }

      .content-card {
        position: relative;
        overflow: hidden;
        padding: 5px 50px;

        .img-content {
          max-width: 272px;
          max-height: 200px;
          margin: 0 auto;
        }
      }
    }
    .note {
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      color: rgba(0, 0, 0, 0.54);
      padding-bottom: 35px;
      p {
        margin-bottom: 5px;
      }
    }
    .btn-download {
      border-radius: 24px;
      background-color: #2987fd;
      border: 1px solid #2987fd;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      color: #fff;
      padding: 12px;
      max-width: 300px;
      width: 100%;
      display: block;
      margin: 0 auto;
      &:hover,
      &:active,
      &:focus {
        background-color: #317cda;
      }
    }
  }
}
.button-download {
  text-align: center;
  margin: 20px 0;
  &#sticky {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-bottom: 0;
    height: 80px;
    background-color: #fff;
    box-shadow: 0 -4px 15px 4px rgba(0, 0, 0, 0.08);
    padding-top: 16px;
    z-index: 30;
  }
}
</style>
